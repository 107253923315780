import React, { useEffect, useRef, useState } from 'react';
import * as musicMetadata from 'music-metadata-browser';
import CreateNote, {
  CrateNoteForm,
  CreateNoteFormData,
} from '../Note/CreateNote';
import CreateNoteRoutingPath, {
  CreateNoteRoutingPathKey,
} from '../../routing/CreateNoteRoutingPath';
import { AxiosError } from 'axios';
import Result from './Result';
import { CardTitle } from '../ui/Card/CardTitle';
import { useQuery } from '@tanstack/react-query';
import RecordingProcess from './RecordingProcess';
import TranscribingProcess from './TranscribingProcess';
import DialogApi from '../../api/DialogApi';
import NotesApi from '../../api/NotesApi';
import Page from '../Page/Page';
import { useToast } from '../ui/Toast/UseToast';
import { useAuth0 } from '@auth0/auth0-react';
import ResultApi from '../../api/ResultApi';
import { useUserData } from '../../context/UserContextProvider';
import { Cdss } from '../../result/Cdss';
import CdssApi from '../../api/CdssApi';

export interface DeepgramWord {
  punctuated_word: string;
  speaker: number;
}

export interface DeepgramTranscription {
  transcript?: string;
  words?: DeepgramWord[];
}

export interface DeepgramChannel {
  alternatives?: DeepgramTranscription[];
}

export interface DeepgramResultsWithChannels {
  channels?: DeepgramChannel[];
}

export interface DeepgramFullResult {
  metadata?: DeepgramMetadata;
  results?: DeepgramResultsWithChannels;
}

export interface DeepgramMetadata {
  channels: number;
  created: string;
}

export interface PreprocessingDialogMessage {
  text: string;
  speaker: number;
  start: number;
  end: number;
}

export interface SOAPNote {
  subjective?: string;
  objective?: string;
  assessment?: string;
  plan?: string;
  summary?: string;
}

interface Choice {
  message: string;
}

interface CompletionUsage {
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
}

export interface OpenAIResponse {
  id?: string;
  created?: number;
  model?: string;
  choices?: Choice[];
  usage?: CompletionUsage;
}

export type SpeakerKey = 'patient' | 'doctor';

export interface DeepgramResponse {
  _id?: string;
  name: string;
  patientId?: string;
  patientName?: string;
  userId: string;
  result?: DeepgramFullResult;
  note?: SOAPNote;
  cdss?: Cdss;
  preprocessingDialog?: PreprocessingDialogMessage[];
  openaiResult?: OpenAIResponse;
  speaker0?: SpeakerKey;
  speaker1?: SpeakerKey;
  editDialog?: PreprocessingDialogMessage[];
  createdAt: string;
  updatedAt: string;
}

interface DeepgramCallbackResponse {
  resultId: string;
}

export default function RenderNote() {
  const { userData, refreshUserData } = useUserData();
  const { toast } = useToast();
  const { user, getAccessTokenSilently } = useAuth0();
  const [tab, setTab] = useState<CreateNoteRoutingPathKey>('create');

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recording, setRecording] = useState(false);
  const [result, setResult] = useState<DeepgramResponse>(
    {} as DeepgramResponse
  );
  const [message, setMessage] = useState<string>();
  const [resultId, setResultId] = useState<string>('');
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(true);

  const resultQuery = useQuery({
    queryKey: ['result'],
    queryFn: async () => {
      try {
        if (!user?.sub) {
          throw new Error('No user id');
        }
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error('No access token');
        }
        const response = await new ResultApi().getById(
          resultId,
          user.sub,
          token
        );
        const {
          result: fullResult,
          preprocessingDialog,
          openaiResult,
          cdss,
        } = response;
        if (fullResult && preprocessingDialog && openaiResult && cdss) {
          setShouldRefetch(false);
          setResult(response);
          setTab(CreateNoteRoutingPath.RESULT as CreateNoteRoutingPathKey);
        }
      } catch (error) {
        setShouldRefetch(false);
        console.error('Error accessing the microphone:', error);
        setMessage((error as unknown as Error).message);
        setTab(CreateNoteRoutingPath.ERROR as CreateNoteRoutingPathKey);
      }
    },
    enabled: !!shouldRefetch,
    refetchInterval: (query) => {
      return 4 * 1000;
    },
  });

  const handleSaveAndSend = async (
    audioBlob: Blob,
    { noteName, patient }: CreateNoteFormData,
    metadata?: musicMetadata.IAudioMetadata
  ) => {
    if (audioBlob) {
      try {
        const formData = new FormData();
        formData.append('audio', audioBlob);
        formData.append('name', noteName);
        formData.append('lang', user?.lang || 'en');
        if (patient?._id && patient?.name) {
          formData.append('patientId', patient._id);
          formData.append('patientName', patient.name);
        }

        if (metadata) {
          formData.append(
            'channels',
            `${metadata.format.numberOfChannels}` || ''
          );
        }

        if (!user?.sub) {
          throw new Error('No user id');
        }
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error('No access token');
        }
        const response = await new ResultApi().postAudio(
          user.sub,
          formData,
          token
        );
        setResultId(response.resultId);
        setShouldRefetch(true);
      } catch (err) {
        const error = err as unknown as AxiosError;
        const status = error.response?.status;
        if (status === 409) {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: 'Session limit exceeded',
          });
        }
        console.error('Error sending audio to server:', err);
        setMessage((error as unknown as Error).message);
        setTab(CreateNoteRoutingPath.ERROR as CreateNoteRoutingPathKey);
      }
    }
  };

  const startRecording = (data: CreateNoteFormData) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: Blob[] = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.push(event.data as Blob);
          }
        };

        mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });

          let metadata;
          try {
            metadata = await musicMetadata.parseBlob(audioBlob);
          } catch (e) {
            console.log('meta error');
            console.log(e);
          }
          console.log('meta', metadata);
          await handleSaveAndSend(audioBlob, data, metadata);
        };

        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        setRecording(true);
      })
      .catch((error) => {
        console.error('Error accessing the microphone:', error);
        setMessage(error.message);
        setTab(CreateNoteRoutingPath.ERROR as CreateNoteRoutingPathKey);
      });
  };

  const handleStop = async () => {
    stopRecording();
    setTab(
      CreateNoteRoutingPath.TRANSCRIPTIONPROCESS as CreateNoteRoutingPathKey
    );
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleTranscriptionEdit = async (
    dialog: PreprocessingDialogMessage[]
  ) => {
    try {
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new DialogApi().edit(resultId, user.sub, dialog, token);
      toast({
        title: 'Success',
        description: 'Transcription saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.log(e);
    }
  };

  const handleNoteEdit = async (note: SOAPNote) => {
    try {
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new NotesApi().edit(resultId, user.sub, note, token);
      toast({
        title: 'Success',
        description: 'SOAP note saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.log(e);
    }
  };

  const handleCdssEdit = async (cdss: Cdss) => {
    try {
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new CdssApi().edit(resultId, user.sub, cdss, token);
      toast({
        title: 'Success',
        description: 'CDSS saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.log(e);
    }
  };

  const handleReset = () => {
    setResult({} as DeepgramResponse);
    setMessage('');
    setResultId('');
    setShouldRefetch(false);
    setTab(CreateNoteRoutingPath.CREATE as CreateNoteRoutingPathKey);
  };

  const renderTab = (key: CreateNoteRoutingPathKey) => {
    switch (key) {
      case CreateNoteRoutingPath.CREATE:
        return (
          <CreateNote
            onClick={(data) => {
              setMessage('');
              startRecording(data);
              setTab(
                CreateNoteRoutingPath.RECORDING as CreateNoteRoutingPathKey
              );
            }}
          />
        );
      case CreateNoteRoutingPath.RECORDING:
        return <RecordingProcess isRecording={recording} onStop={handleStop} />;
      case CreateNoteRoutingPath.TRANSCRIPTIONPROCESS:
        return (
          <TranscribingProcess
            message={message}
            onClick={() => {
              setTab(CreateNoteRoutingPath.CREATE as CreateNoteRoutingPathKey);
            }}
          />
        );
      case CreateNoteRoutingPath.RESULT:
        return (
          <Result
            data={result}
            onTranscriptionSave={handleTranscriptionEdit}
            onNoteSave={handleNoteEdit}
            onCdssSave={handleCdssEdit}
            onReset={handleReset}
          />
        );
      case CreateNoteRoutingPath.ERROR:
        return <CardTitle>{message}</CardTitle>;
      default:
        return (
          <CreateNote
            onClick={(data) => {
              setMessage('');
              startRecording(data);
              setTab(
                CreateNoteRoutingPath.RECORDING as CreateNoteRoutingPathKey
              );
            }}
          />
        );
    }
  };

  return <Page>{renderTab(tab)}</Page>;
}
