import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeepgramResponse } from './RenderNote';
import { InputWithLabel } from '../ReusableElements/InputWithLabel';
import { Separator } from '../ui/Separator';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/Button';
import NotesApi from '../../api/NotesApi';
import { useToast } from '../ui/Toast/UseToast';
import { useUserData } from '../../context/UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { CardDescription } from '../ui/Card/CardDescription';
import { format } from 'date-fns';

const schema = z.object({
  noteName: z.string().min(1, {
    message: 'Name must have at least one character',
  }),
  patientName: z.string().min(1, {
    message: 'Patient name must have at least one character',
  }),
});

interface SoapNoteDetailsProps {
  result: DeepgramResponse;
  resultId: string;
}

const SoapNoteDetails = ({ result, resultId }: SoapNoteDetailsProps) => {
  const { userData } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { toast } = useToast();
  const { t } = useTranslation();
  const [noteName, setNoteName] = useState(result.name);
  const [patientName, setPatientName] = useState(result.patientName);

  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  if (!resultId) {
    return <h2>No note provided</h2>;
  }

  const onSubmit = async (data: any) => {
    try {
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new NotesApi().editName(
        resultId,
        user.sub,
        { name: data.noteName },
        token
      );
      toast({
        title: 'Success',
        description: 'SOAP note name saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.log(e);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <h2 className="text-xl font-bold">Details</h2>
      {result.updatedAt ? (
        <CardDescription className="mt-1">
          Last updated:{' '}
          {format(new Date(result.updatedAt), 'dd-MM-yyyy HH:mm:ss')}
        </CardDescription>
      ) : null}
      <Separator className="my-5" />
      <InputWithLabel
        label={t('note.noteName')}
        id="name"
        placeholder="Name"
        value={noteName}
        register={register}
        registerName="noteName"
        errors={errors}
        onChange={(newValue) => {
          setNoteName(newValue);
        }}
      />
      <InputWithLabel
        label={t('note.patient')}
        id="patientName"
        placeholder="Patient name"
        value={patientName}
        register={register}
        registerName="patientName"
        errors={errors}
        onChange={(newValue) => {
          setPatientName(newValue);
        }}
      />
      <Button type="submit">{t('button.save')}</Button>
    </form>
  );
};

export default SoapNoteDetails;
