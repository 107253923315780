import React from 'react';
import { Label } from '../ui/Label';
import { Input } from '../ui/Input';
import { UseFormRegister } from 'react-hook-form';

type Props = {
  value?: string;
  label: string;
  id?: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  registerName: string;
  errors: any;
  type?: string;
  onChange?: (value: string) => void;
  name?: string;
};

export const InputWithLabel: React.FC<Props> = ({
  label,
  id,
  placeholder,
  register,
  registerName,
  errors,
  type,
  value,
  onChange,
  name = '',
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    // e.stopPropagation();
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="mb-2 w-full">
      <Label className="font-bold pb-1.5">{label}</Label>

      <Input
        {...register(registerName)}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
      />

      {errors[registerName || ''] ? (
        <p className="text-xs text-red-500">
          {errors[registerName || ''].message}
        </p>
      ) : null}
    </div>
  );
};
